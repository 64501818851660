import React, { useState, useEffect, useContext } from "react";
import ic_dashboard_s from "../assets/images/sidebar/ic_dashboard_s.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import ic_legal from "../assets/images/sidebar/ic_legal.svg";
import mgt from "../assets/images/sidebar/mgt.svg";
import ic_payment_method from "../assets/images/sidebar/ic_payment_method.svg";
import ic_reports from "../assets/images/sidebar/ic_reports.svg";
import ic_faq from "../assets/images/sidebar/ic_faq.svg";
import ic_how from "../assets/images/sidebar/ic_how.svg";
import right_arrow from "../assets/images/sidebar/ic_sarrow.svg";
import dropdown from "../assets/images/sidebar/sidemenu_dropdown.svg";
import Abay_logo from "../assets/images/sidebar/Abay_logo.png";
import ic_integration from "../assets/images/sidebar/ic_integration.svg";
import arrow_highlight from "../assets/images/sidebar/arrow_highlight.svg";
import useMediaQuery from "../assets/MediaQuery";
import { AppContext } from "../context/user/AppContext";
import ic_vehicle from "../assets/images/AddVehicles/ic_vehicle.svg";

const Sidebar = () => {
  let isPageWide = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  let currentRoute = location.pathname;

  const { sidebar, setSidebar, setMainClass, setMainClassB } =
    useContext(AppContext);
  const [dash, setDash] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [show, setShow] = useState(false);
  const [offer, setOffer] = useState(false);
  const [goods, setGoods] = useState(false);
  const [gps, setGps] = useState(false);
  const [regist, setRegist] = useState(false);
  const [cargo, setCargo] = useState(false);
  const [online, setOnline] = useState(false);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    isPageWide ? setSidebar(false) : setSidebar(true);
  }, [isPageWide]);

  const showSidebar = () => {
    if (sidebar) {
      setShow(false);
      setState(false);
    }
    setSidebar(!sidebar);
  };
  const menus = () => {};

  function handleSidebar() {
    // setSidebar(!sidebar)
    // let cxmain = document.getElementById("cxMain");
    // let cxMainB = document.getElementById("cxMainB");
    if (!sidebar) {
      setMainClass("cx-active-sidebar");
    } else {
      setMainClass("");
    }

    if (!sidebar) {
      setMainClassB("cx-active-sidebarB ");
    } else {
      setMainClassB("");
    }
  }

  // function handleSidebar() {
  //   let cxmain = document.getElementById("cxMain");
  //   let cxMainB = document.getElementById("cxMainB");
  //   if (!sidebar) {
  //     if (cxmain) {
  //       cxmain.classList.add("cx-active-sidebar");
  //     }
  //     if (cxMainB) {
  //       cxMainB.classList.add("cx-active-sidebarB");
  //     }
  //   } else {
  //     if (cxmain) {
  //       cxmain.classList.remove("cx-active-sidebar");
  //     }
  //     if (cxMainB) {
  //       cxMainB.classList.remove("cx-active-sidebarB");
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <div className="sidebar">
        <aside id="cx-sidebar" className={sidebar ? "cx-active" : null}>
          {sidebar ? (
            <div className="cx-sidebar-brand">
              <div className="brandname">
                <Link className="abay text-decoration-none" to="/Dashboard">
                  <div className="d-flex brandbox">
                    <NavLink to="/Dashboard">
                      <img className="logoActive" src={Abay_logo} alt="" />
                    </NavLink>
                    <h4 className="brandActive ms-3 mt-3">Abay Logistics</h4>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <Link to="/Dashboard">
              <img className="cx-brand-logo" src={Abay_logo} alt="" />
            </Link>
          )}
          <div
            className={`cx-sidebar-toggler ${sidebar ? "cx-active" : null}`}
            id="cx-sidebar-toggler"
          >
            <button
              onClick={() => {
                showSidebar();
                handleSidebar();
                setOpen(!open);
              }}
              type="button"
              className="cx-toggle-btn d-flex align-items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgba(0, 0, 0, 1)" }}
              >
                <path d="m13.061 4.939-2.122 2.122L15.879 12l-4.94 4.939 2.122 2.122L20.121 12z"></path>
                <path d="M6.061 19.061 13.121 12l-7.06-7.061-2.122 2.122L8.879 12l-4.94 4.939z"></path>
              </svg>
            </button>
          </div>

          {/* Sidebar Menu list  */}

          <div className="list">
            <ul className={`cx-list  ${sidebar ? "cx-active" : null}`}>
              {/* ------------------------------- Dashboard */}

              <li className="cx-nav-active">
                <NavLink
                  to="/Dashboard"
                  className="icon_arrow"
                  style={({ isActive }) => {
                    return { opacity: isActive ? "1" : "0.5" };
                  }}
                >
                  <div className="d-flex active">
                    <img src={ic_dashboard_s} alt="send-load-icon" />
                    <span>Dashboard</span>
                  </div>
                </NavLink>
                <div className="cx-tooltip">Dashboard</div>
              </li>

              {/* ---------------------------------------MGT  */}
              <li className="cx-nav-active">
                <Link
                  onClick={() => setShow(!show)}
                  to="#"
                  className={
                    `icon_arrow ` +
                    ((currentRoute === "/Contracts" ||
                      currentRoute === "/GPS_Shipper" ||
                      currentRoute === "/FreightsHeaders") &&
                      "parentActive")
                  }
                >
                  {/* 
                  AddTransporterSecond
                  TransporterDetails
                  ContractTransporter
                  AddShipper
                  AddShipperSecond
                  ShipperDetails
                  ContractShipper
                  AddVehicles
                  VehicleDriverDetailsHeader
                  AddDriver
       */}
                  <div className="d-flex">
                    <img
                      src={mgt}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Transport MGT.System
                    </span>
                  </div>
                  {show ? (
                    <img
                      className="Arrow_hidde  "
                      src={dropdown}
                      id="icon_Show"
                    />
                  ) : (
                    <img
                      className="Arrow_hidde "
                      src={right_arrow}
                      id="icon_Show"
                    />
                  )}
                </Link>
                <div className="cx-tooltip">Transport MGT.System</div>
              </li>
              {show && (
                <div in={open}>
                  <div
                    id="example-collapse-text"
                    className="sub-menumgt"
                    style={{ transition: "all 1s" }}
                  >
                    <ul>
                      {/* <li className="mgt">
                        <Link
                          onClick={() => setRegist(!regist)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/VehicleList" ||
                              currentRoute === "/driverlist" ||
                              currentRoute === "/TransporterLists" ||
                              currentRoute === "/AddTransporter" ||
                              currentRoute === "/ShippersLists") &&
                              "parentActive")
                          }
                        >
                          <div className="d-flex ">
                            <span>Registration</span>
                          </div>
                          {regist ? (
                            <img
                              className="Arrow_hidde  "
                              src={dropdown}
                              id="icon_Show"
                            />
                          ) : (
                            <img
                              className="Arrow_hidde "
                              src={right_arrow}
                              id="icon_Show"
                            />
                          )}
                        </Link>
                      </li> */}
                      {regist && (
                        <div>
                          <div id="example-collapse-text">
                            <ul
                              style={{
                                background: "#874F41",
                                marginLeft: "-2rem",
                                boxShadow: "inset 0px 0px 6px #864D1C",
                              }}
                              className="innerMenu-border"
                            >
                              <li className="good ">
                                <NavLink
                                  style={({ isActive }) => {
                                    return {
                                      display: isActive ? "" : " ",
                                      opacity: isActive ? "1" : "0.5",
                                    };
                                  }}
                                  to="/TransporterLists"
                                  className="MG "
                                >
                                  <img
                                    src={arrow_highlight}
                                    // className={this.childNode.parentNode.className.contains("active")? "Arrow_hidde" : ""}
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Transporters
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/ShippersLists"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Shippers
                                </NavLink>
                              </li>

                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/VehicleList"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Vehicles
                                </NavLink>
                              </li>

                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/driverlist"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Drivers
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink to="#" className="MGT">
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  MOU
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <li className="mgt">
                        {/* <Link to="#gps" data-target="#gps" className="MGT">
                          {" "}
                          <span onClick={() => setGps(!gps)}>
                          
                           
                          </span>
                        </Link> */}
                        <Link
                          onClick={() => setGps(!gps)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/GPS_Shipper" ||
                              currentRoute === "/FreightsHeaders" ||
                              currentRoute === "/Contracts") &&
                              "parentActive")
                          }
                        >
                          <div className="d-flex ">
                            <span>GPS Fleet Management</span>
                          </div>
                          {gps ? (
                            <img
                              className="Arrow_hidde  "
                              src={dropdown}
                              id="icon_Show"
                            />
                          ) : (
                            <img
                              className="Arrow_hidde "
                              src={right_arrow}
                              id="icon_Show"
                            />
                          )}
                        </Link>
                      </li>
                      {gps && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul
                              style={{
                                background: "#874F41",
                                marginLeft: "-2rem",
                                boxShadow: "inset 0px 0px 6px #864D1C",
                              }}
                              className="innerMenu-border"
                            >
                              {/* <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/GPS_Shipper"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Live Tracking
                                </NavLink>
                              </li> */}
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/FreightsHeaders"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Freights
                                </NavLink>
                              </li>
                              {/* <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/Contracts"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Contract
                                </NavLink>
                              </li> */}
                            </ul>
                          </div>
                        </collapse>
                      )}
                    </ul>
                  </div>
                </div>
              )}

              {/* ----------------------------             Transport Marketplace */}
              <li className="cx-nav-active">
                <Link
                  onClick={() => setState(!state)}
                  to="#"
                  className={
                    `icon_arrow ` +
                    ((currentRoute == "/OfferGoodsList" ||
                      currentRoute == "/AddOfferGoods" ||
                      currentRoute == "/DetailsOfferGoods" ||
                      currentRoute == "/VehicleOffers" ||
                      currentRoute == "/OrderConfirmation" ||
                      currentRoute == "/OnlineVehicleOffers" ||
                      currentRoute == "/OnlineOrderConfirmation") &&
                      "parentActive")
                  }
                >
                  <div className="d-flex">
                    <img
                      src={ic_legal}
                      alt="load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span
                      aria-controls="example-collapse-text"
                      aria-expanded={state}
                    >
                      Transport Marketplace
                    </span>
                  </div>
                  {state ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde  "
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde"
                      alt="send-load-icon"
                    />
                  )}
                </Link>
                <div className="cx-tooltip">Transport Marketplace</div>
              </li>
              {state && (
                <collapse in={open}>
                  <div id="example-collapse-text">
                    <ul>
                      {/* -----------------------offer loads */}

                      {/* {goods && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <Link to="/VehicleOffRecived" className="MGT">
                                  {" "}
                                  Vehicle Offers
                                </Link>
                              </li>
                              <li className="good">
                                <Link to="/VehicalRequest" className="MGT">
                                  {" "}
                                  Vehicle Requests
                                </Link>
                              </li>
                              <li className="mgt">
                                <Link to="#" className="MGT">
                                  {" "}
                                  Contract
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )} */}
                      {/* Delivery System */}
                      <li className="cx-nav-active">
                        <NavLink
                          style={({ isActive }) => {
                            return { opacity: isActive ? "1" : "0.5" };
                          }}
                          to="/OfferGoodsList"
                          // className="icon_arrow"
                        >
                          <span onClick={() => setGoods(!goods)}>
                            Offer Load
                          </span>
                        </NavLink>
                      </li>
                      {/* -----------------------------Direct order  */}
                      <li className="cx-nav-active">
                        <Link
                          onClick={() => setOffer(!offer)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/VehicleOffers" ||
                              currentRoute === "/OrderConfirmation") &&
                              "parentActive")
                          }
                        >
                          <span>Direct Orders</span>

                          {offer ? (
                            <img
                              src={dropdown}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="dropdown"
                            />
                          ) : (
                            <img
                              src={right_arrow}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="Right"
                            />
                          )}
                        </Link>
                      </li>

                      {offer && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul
                              style={{
                                background: "#874F41",
                                marginLeft: "-2rem",
                                boxShadow: "inset 0px 0px 6px #864D1C",
                              }}
                            >
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/VehicleOffers"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Vehicle
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OrderConfirmation"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Order Confirmation
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )}

                      {/* <li className="cx-nav-active">
                        <Link
                          onClick={() => setOnline(!online)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute == "/OnlineVehicleOffers" ||
                              currentRoute == "/OnlineOrderConfirmation") &&
                              "parentActive")
                          }
                        >
                          <span>Online Auction</span>

                          {online ? (
                            <img
                              src={dropdown}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="dropdown"
                            />
                          ) : (
                            <img
                              src={right_arrow}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="Right"
                            />
                          )}
                        </Link>
                      </li>
                      {online && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul
                              style={{
                                background: "#874F41",
                                marginLeft: "-2rem",
                                boxShadow: "inset 0px 0px 6px #864D1C",
                              }}
                            >
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OnlineVehicleOffers"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Vehicle
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OnlineOrderConfirmation"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Order Confirmation
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )} */}

                      {/* ===========End Online auction============== */}

                      {/* <li className="cx-nav-active">
                        <NavLink
                          to="/"
                          style={({ isActive }) => {
                            return { opacity: isActive ? "1" : "0.5" };
                          }}
                          className=
                            "icon_arrow "
                            
                        >
                          <span onClick={() => setCargo(!cargo)}>
                            Cargo Delivery
                          </span>

                          {cargo ? (
                            <img
                              src={dropdown}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="dropdown"
                            />
                          ) : (
                            <img
                              src={right_arrow}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="Right"
                            />
                          )}
                        </NavLink>
                      </li> */}
                      {/* {cargo && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/VehicleOffer"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Vehicle Offers
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/Cargo_Delivery"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Vehicle Requests
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  to="/Contracts"
                                  className="MGT"
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Contract
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )} */}
                      {/* <li className="cx-nav-active">
                        <Link
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute == "/OrderConfirmation" ||
                              currentRoute == "/TransporterOrderConfirm1") &&
                              "parentActive")
                          }
                        >
                          <span onClick={() => setConfirm(!confirm)}>
                            Order Confirmation
                          </span>

                          {confirm ? (
                            <img
                              src={dropdown}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="dropdown"
                            />
                          ) : (
                            <img
                              src={right_arrow}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="Right"
                            />
                          )}
                        </Link>
                      </li>
                      {confirm && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OrderConfirmation"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  For Offered Goods
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/TransporterOrderConfirm1"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  For Offered Vehicles
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )} */}
                    </ul>
                  </div>
                </collapse>
              )}
              {/* Delivery System */}
              <li className="cx-nav-active">
                <a
                  href="#"
                  className="icon_arrow"
                >
                  <div className="d-flex ">
                    <img
                      style={{
                        width: "34px",
                      }}
                      src={ic_vehicle}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>Delivery System</span>
                  </div>
                  {/* <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde "
                    alt="send-load-icon"
                  /> */}
                </a>
                <div className="cx-tooltip">Delivery System</div>
              </li>
              {/* Reports */}
              <li className="cx-nav-active">
                <NavLink
                  style={({ isActive }) => {
                    return { opacity: isActive ? "1" : "0.5" };
                  }}
                  to="/Reports"
                  className="icon_arrow"
                >
                  <div className="d-flex ">
                    <img
                      src={ic_reports}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>Reports</span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde "
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">Reports</div>
              </li>
              {/* payments */}
              <li className="cx-nav-active">
                <NavLink
                  // style={({ isActive }) => {
                  //   return { opacity: isActive ? "1" : "0.5" };
                  // }}
                  to="#"
                  className="icon_arrow"
                >
                  <div className="d-flex ">
                    <img
                      src={ic_payment_method}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>Payments</span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde "
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">Payments</div>
              </li>
              {/* Integration */}
              <li className="cx-nav-active">
                <NavLink to="#" className="icon_arrow">
                  <div className="d-flex ">
                    <img
                      src={ic_integration}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span onClick={() => setDash(!dash)}>Integration</span>
                  </div>
                  {dash ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde"
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde "
                      alt="send-load-icon"
                    />
                  )}
                </NavLink>
                <div className="cx-tooltip">Integration</div>
              </li>
              {/* FAQ's  */}
              <li className="cx-nav-active">
                <NavLink
                  style={({ isActive }) => {
                    return { opacity: isActive ? "1" : "0.5" };
                  }}
                  to="#"
                  className="icon_arrow"
                >
                  <div className="d-flex">
                    <img
                      src={ic_faq}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>FAQ’S</span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde"
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">FAQ’S </div>
              </li>
              {/* How its works  */}
              <li className="cx-nav-active">
                <NavLink
                  // style={({ isActive }) => {
                  //   return { opacity: isActive ? "1" : "0.5" };
                  // }}
                  to="#"
                  className="icon_arrow"
                >
                  <div className="d-flex ">
                    <img
                      src={ic_how}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>How it works? </span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde "
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">How it works?</div>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
