import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import ic_remove from "../assets/images/ic_remove.svg";
import ic_upload_document from "../assets/images/ic_upload_document.svg";
import { AppContext } from "../context/user/AppContext";

import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import { dataURLtoFile, toBase64 } from "../fileservice/fileService";
const AddShipperSecond = () => {
  window.onload = () => {
    let shipperData = JSON.parse(sessionStorage.getItem("shipperData"));
    let profile_picture = dataURLtoFile(
      shipperData.profile_picture,
      "profile_picture"
    );
    let tn_document = dataURLtoFile(shipperData.tn_document, "tn_document");
    let grade_certificate = dataURLtoFile(
      shipperData.grade_certificate,
      "grade_certificate"
    );
    let business_license = dataURLtoFile(
      shipperData.business_license,
      "business_license"
    );
    setShipperDetails({
      ...shipperData,
      profile_picture,
      tn_document,
      grade_certificate,
      business_license,
    });
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      gridTemplateColumns: "0px 100%",
    }),
  };
  const navigate = useNavigate();
  const {
    customerData,
    ApiCaaling,
    shipperDetails,
    setShipperDetails,
    setFile,
    file,
  } = useContext(AppContext);
  const [currencyList, setCurrenctList] = useState([]);
  const [chargeTypes, setchargeTypesList] = useState([]);
  const [quantityUnits, setquantityUnitsList] = useState([]);
  const [fileSize, setFileSize] = useState(false);

  const [errMsg, setErrMsg] = useState({
    contract: "",
    charge_type: "",
    rateper_20gp: "",
    rateper_40gp: "",
    rateper_quintal: "",
    currency: "",
    remarks: "",
    unit: "",
  });
  useEffect(async () => {
    sessionStorage.setItem("shipperData", JSON.stringify(...shipperDetails));
  }, [shipperDetails]);

  useEffect(() => {
    if (ApiCaaling) document.getElementById("continue-btn").disabled = true;
    else document.getElementById("continue-btn").disabled = false;
  }, [ApiCaaling]);
  const Add = (e) => {
    // e.preventdefault();
    const formData = new FormData();
      if (
        shipperDetails.contract &&
        ((shipperDetails.contract.name && shipperDetails.contract.name) ||
          shipperDetails.contract.length > 0)
      )
        if (
          shipperDetails.charge_type === "" ||
          shipperDetails.rateper_20gp === "" ||
          shipperDetails.rateper_40gp === "" ||
          shipperDetails.rateper_quintal === "" ||
          shipperDetails.currency === ""
          // shipperDetails.remarks === ""
        ) {
          if (shipperDetails.charge_type === "") {
            setErrMsg({ ...errMsg, charge_type: "Charge type is required" });
            return;
          }
          if (shipperDetails.rateper_20gp === "") {
            setErrMsg({ ...errMsg, rateper_20gp: "Rate per 20gp is required" });
            return;
          }
          if (shipperDetails.rateper_40gp === "") {
            setErrMsg({ ...errMsg, rateper_40gp: "Rate per 40gp is required" });
            return;
          }
          if (shipperDetails.rateper_quintal === "") {
            setErrMsg({
              ...errMsg,
              rateper_quintal: "Rate per quintal is required",
            });
            return;
          }
          if (shipperDetails.currency === "") {
            setErrMsg({ ...errMsg, currency: "Currency is required" });
            return;
          }
          // if (shipperDetails.remarks === "") {
          //   setErrMsg({ ...errMsg, remarks: "Remarks is required" });
          //   return;
          // }
        }

    formData.append("company_name", shipperDetails.company_name);
    formData.append("email", shipperDetails.email);
    shipperDetails.password &&
      formData.append("password", shipperDetails.password);
    formData.append("phone_no", shipperDetails.phone_no);
    formData.append("city", shipperDetails.city);
    formData.append("region", shipperDetails.region);
    formData.append("country", shipperDetails.country);
    formData.append("po_number", shipperDetails.po_number);
    formData.append("contact_person", shipperDetails.contact_person);
    formData.append(
      "contact_person_responsibility",
      shipperDetails.contact_person_responsibility
    );
    formData.append(
      "contact_person_phone",
      shipperDetails.contact_person_phone
    );
    formData.append(
      "contact_person_email",
      shipperDetails.contact_person_email
    );
    if (shipperDetails.contract && shipperDetails.contract.length > 0) {
      shipperDetails.contract &&
        shipperDetails.contract.includes("base64") &&
        formData.append(
          "contract",
          dataURLtoFile(shipperDetails.contract, "contract")
        );
      formData.append("charge_type", shipperDetails.charge_type);
      formData.append("rateper_20gp", shipperDetails.rateper_20gp);
      formData.append("rateper_40gp", shipperDetails.rateper_40gp);
      formData.append("rateper_quintal", shipperDetails.rateper_quintal);
      formData.append("currency", shipperDetails.currency);
      formData.append("remarks", shipperDetails.remarks);
    }
    formData.append("total_fleet_size", shipperDetails.total_fleet_size);
    formData.append("alternate_phone", shipperDetails.alternate_phone);

    shipperDetails.profile_picture &&
      shipperDetails.profile_picture.length &&
      shipperDetails.profile_picture.includes("base64") &&
      formData.append(
        "profile_picture",
        dataURLtoFile(shipperDetails.profile_picture, "profile_picture")
      );
    shipperDetails.business_license &&
      shipperDetails.business_license.includes("base64") &&
      formData.append(
        "business_license",
        dataURLtoFile(shipperDetails.business_license, "business_license")
      );
    shipperDetails.grade_certificate &&
      shipperDetails.grade_certificate.includes("base64") &&
      formData.append(
        "grade_certificate",
        dataURLtoFile(shipperDetails.grade_certificate, "grade_certificate")
      );
    shipperDetails.tn_document &&
      shipperDetails.tn_document.includes("base64") &&
      formData.append(
        "tn_document",
        dataURLtoFile(shipperDetails.tn_document, "tn_document")
      );
    formData.append("user_id", customerData.user_id);
    formData.append("business_area", shipperDetails.business_area);
    shipperDetails.id && formData.append("shipper_id", shipperDetails.id);

    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.REGISTER_DETAILS,
      formData
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        swal(res.json.message);
        if (res.json.result) navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCurrencyDropDown();
    getOtherDropDown();
  }, []);

  const getCurrencyDropDown = () => {
    PostCallWithErrorResponse(ApiConfig.CURRENCY_DROPDOWN)
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setCurrenctList(
          res.json.currency_list.map((currency) => {
            return {
              value: currency.currency_id,
              label: currency.currency_name,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOtherDropDown = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_SHIPPER_DROPDOWN,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setchargeTypesList(res.json.charge_types);
          setShipperDetails({
            ...shipperDetails,
            charge_type:
              res.json.charge_types.filter(
                (charge) =>
                  charge.charge_type_name === shipperDetails.charge_type
              ) &&
              res.json.charge_types.filter(
                (charge) =>
                  charge.charge_type_name === shipperDetails.charge_type
              )[0].charge_type_id,
          });
          setquantityUnitsList(res.json.quantity_units);
          // setCurrenctList(res.json.currency_list.map(currency => { return { value: currency.currency_code, label: currency.currency_name } }))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main
      className={`main-section-AddDriver`}
      id="cxMain"
      style={{ marginLeft: "20px" }}
    >
        <div className="main-body-AddDriver " style={{ marginBottom: "80px" }}>
        {/* Progress bar block */}
        <section
          className="progress-block"
          style={{ backgroundColor: "#FBF7EF" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="main-content p-4 row">
                <div className="left col-lg-9 col-md-8">
                  <h4 className="heading-progress">ADD SHIPPER</h4>
                  <div
                    className="progress"
                    style={{ height: "8px", margin: "10px", padding: "2px" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%", backgroundColor: "#8a443d" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="right col-lg-3 col-md-4 mt-4 ">
                  <p className="progress-status">Contract Details (2/2)</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="upload-contract mt-4 d-flex justify-content-between align-items-end">
          <div>
            <label htmlFor="" className="">
              Upload Contract
            </label>
            {fileSize && fileSize > 2000000 && (
              <p className="text-danger">
                File size cannot exceed more than 2MB
              </p>
            )}

            <div className="d-flex">
              <div
                className="uploaded_file-transporter d-flex justify-content-between mt-1"
                style={{
                  backgroundColor: "#ffff",
                  border: "1px solid #D3DBDB",
                  borderRadius: "8px",
                  padding: "5px 10px",
                  minWidth: "300px",
                }}
              >
                <label
                  className="col-4 text-truncate "
                  style={{ width: "100px" }}
                >
                  {shipperDetails.contract}
                </label>
                <a href="#">
                  <img
                    src={ic_remove}
                    alt=""
                    className=""
                    style={{ verticalAlign: "middle" }}
                  />
                </a>
              </div>
              <div className="ms-2 icons">
                <input
                  type="file"
                  onChange={(e) => {
                    setFileSize(e.target.files[0].size);

                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setShipperDetails({
                        ...shipperDetails,
                        contract: reader.result,
                      });
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                  accept="image/*"
                  id="file"
                />

                <label htmlFor="file">
                  <img
                    src={ic_upload_document}
                    alt=""
                    style={{ verticalAlign: "middle" }}
                  />
                </label>
              </div>
            </div>
          </div>
          {/* <div className="icons upload-and-add_icon">
            <Link to="#">
              <img src={ic_add} className="add" alt="send-load-icon" />
            </Link>
          </div> */}
        </section>
        <section className="main-vehicle-info  mt-4 ">
          <div className="content " style={{ marginBottom: "80px" }}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Type of Charge</label>
                      <select
                        type="text"
                        className="form-select"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={shipperDetails.chargeType}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, charge_type: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            charge_type: e.target.value,
                          });
                        }}
                      >
                        <option>Select Charge Type</option>
                        {chargeTypes &&
                          chargeTypes.length &&
                          chargeTypes.map((chargeType, index) => {
                            return (
                              <option
                                value={chargeType.charge_type_id}
                                key={"chargeType" + index}
                              >
                                {" "}
                                {chargeType.charge_type_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {errMsg.charge_type.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.charge_type}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Per 20* if container are loaded paired
                      </label>
                      <input
                        type="number"
                        value={shipperDetails.rateper_20gp}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, rateper_20gp: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            rateper_20gp: e.target.value,
                          });
                        }}
                      />
                      {errMsg.rateper_20gp.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rateper_20gp}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Per 40gp </label>
                      <input
                        type="number"
                        value={shipperDetails.rateper_40gp}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, rateper_40gp: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            rateper_40gp: e.target.value,
                          });
                        }}
                      />
                      {errMsg.rateper_40gp.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rateper_40gp}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Per Quintal</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        defaultValue={shipperDetails.rateper_quintal}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, rateper_quintal: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            rateper_quintal: e.target.value,
                          });
                        }}
                      />

                      {errMsg.rateper_quintal.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rateper_quintal}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Currency</label>
                      {/* <input list="brow" id="currencyList" className="form-control w-100" onChange={(e) => {
                        setErrMsg({ ...errMsg, currency: "" });
                        setShipperDetails({ ...shipperDetails, currency: e.target.value })
                      }} defaultValue={shipperDetails.currency} />
                      <datalist id="brow" style={{ color: "red" }}>
                        {currencyList.map((currency, index) => {
                          
                          return (< option key={index + currency.value} value={currency.value} label={currency.label} />)
                        })}
                      </datalist> */}
                      <Select
                        options={currencyList}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, currency: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            currency: e.value,
                          });
                        }}
                        styles={customStyles}
                        value={currencyList.filter(
                          (option) => option.value === shipperDetails.currency
                        )}
                      />
                      {errMsg.currency.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.currency}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Remarks</label>
                      <input
                        className="form-control"
                        autocomplete="on"
                        value={shipperDetails.remarks}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, remarks: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            remarks: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* {errMsg.remarks.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.remarks}</span>
                      </div>
                    )} */}
                  </div>
                  {/* <div className="mt-4" style={{ textAlign: "right" }}>
                    <Link to="#" type="btn">
                      <img src={ic_delete} alt="" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="cancel" className="cancel-btn">
              Cancel
            </button>
          </a>
          {/* <Link to="#"> */}
          <button
            type="submit"
            className="continue-btn"
            id="continue-btn"
            onClick={Add}
          >
            Add
          </button>
          {/* </Link> */}
        </div>
      </div>
    </main>
  );
};

export default AddShipperSecond;
